import lozad from 'lozad';
import {ConLog} from './con_log';
const conlog = new ConLog({debug: false});

// class ****** START
export class ConImg {
  // Class constants/variable *** START ***
  static INS_NAME: string = 'conImg';
  static LINK_IMG: any = {
    none: 'link[name=img_none]',
    loading: 'link[name=img_loading]',
  };
  static LOAD_STATUS: any = {
    success: 'success',
    fail: 'fail',
    pending: 'pending',
    cache: 'cache',
  };
  static CACHE_KEY: any = {
    success: 'cache-img-success',
    fail: 'cache-img-fail',
  };
  static CACHE_EXPIRE: number = 2 * 24 * 60 * 60; // 缓存过期时间（2天），以秒为最小单位
  static IMG_ATTR: any = {
    src: 'data-src',
    status: 'load-status'
  };
  static TIMEOUT = 20; // 单次等待时长
  static LOADING_TIME: number = 1500; // 最大加载时间，以 单次时长 为最小单位递增

  // Instance constants/variable *** START ***
  log: any;
  img: any = {
    none: '',
    loading: '',
  };
  maxCount: number; // 加载次数

  // Initialization function
  constructor() {
    const methodName = this.setMethodName('init');
    const ins = this;
    const insClass = ConImg;
    ins.log = conlog;
    ins.maxCount = insClass.LOADING_TIME / insClass.TIMEOUT;

    const linkImg = insClass.LINK_IMG;
    const imgNoneObj = document.querySelector(linkImg.none);
    if (imgNoneObj) ins.img.none = imgNoneObj.href;
    const imgLoadingObj = document.querySelector(linkImg.loading);
    if (imgLoadingObj) ins.img.loading = imgLoadingObj.href;

    ins.log.debug(methodName, "img.none", ins.img.none);
    ins.log.debug(methodName, "img.loading", ins.img.loading);

    if (ins.img.none == '') ins.log.warn(methodName, "Not found <"+ linkImg.none +"> in <head>");
    if (ins.img.loading == '') ins.log.warn(methodName, "Not found <"+ linkImg.loading +"> in <head>");

    // （preload image） 为防止浏览器提示，提前 js 加载
    document.querySelectorAll("link").forEach((link) => {
      if (link.getAttribute('as') === 'image') {
        const url = link.getAttribute('href') || '';
        const img = new Image();
        img.setAttribute('src', url);
      }
    });
  }

  // 设置方法名
  setMethodName(methodName: string): string {
    let _methodName: string = ConImg.INS_NAME;
    if (methodName) _methodName = _methodName + '.' + methodName
    return '[' + _methodName + '] ';
  }

  // 图片检测
  initLoading(): void {
    const methodName = this.setMethodName('initLoading');
    if (this.img.none == '') return;
    const ins = this;

    const observer = lozad('.lozad',{
      rootMargin: '30px 0px',
      threshold: 1,

      load: function(el) { // 生命周期：加载图片前
        // ins.log.debug(methodName, 'lozad before', el, new Date().getTime());
        ins.loadImg(el);
      },
      loaded: function (el) { // 加载完毕，实际图片还在pending中，页面还没显示图片
        // ins.log.debug(methodName, 'lozad after', el, new Date().getTime());
        ins.showImg(el);
      }

    });
    observer.observe();
  }

  // 加载图片
  loadImg(imgObj: any): void {
    const methodName = this.setMethodName('loadImg');
    const ins = this;
    const insClass = ConImg;
    const imgSrc = imgObj.getAttribute(insClass.IMG_ATTR.src);

    if (ins.checkBlank(imgObj) ) {
      ins.loadingNone(imgObj);
      return;
    }
    if (ins.getCacheStatus(imgObj) == insClass.LOAD_STATUS.success) {
      ins.loadingCache(imgObj);
      return;
    }
    if (ins.getCacheStatus(imgObj) == insClass.LOAD_STATUS.fail) {
      ins.loadingNone(imgObj);
      return;
    }
    ins.loadingPending(imgObj);

    let imgTmp = new Image();
    imgTmp.onerror = function() {
      ins.loadingFail(imgObj);
    }

    const appname = navigator.appName.toLowerCase()
    if (appname.indexOf( "netscape" ) == -1) {
      // ie
      imgTmp.onreadystatechange = function() {
        ins.log.debug(methodName,'imgTmp.readyState', imgTmp.readyState);
        if (imgTmp.readyState === "complete") {
          // ins.log.debug(methodName, 'img load success.');
          ins.loadingSuccess(imgObj);
        }
      }
    } else {
      // firefox
      imgTmp.onload = function() {
        ins.log.debug(methodName, 'imgTmp.complete', imgTmp.complete);
        if (imgTmp.complete == true) {
          // ins.log.debug(methodName, 'img load success.');
          ins.loadingSuccess(imgObj);
        }
      }
    }

    imgTmp.src = imgSrc;
  }

  checkBlank(imgObj: any) {
    const insClass = ConImg;
    const imgSrc = imgObj.getAttribute(insClass.IMG_ATTR.src);
    return imgSrc == '';
  }

  // 检查是否加载过
  getCacheStatus(imgObj: any) {
    const methodName = this.setMethodName('getCacheStatus');
    const ins = this;
    const insClass = ConImg;
    const imgSrc = imgObj.getAttribute(insClass.IMG_ATTR.src) || '';
    if (imgSrc == '') return null;
    const encode = imgSrc;

    const successCache = sessionStorage.getItem(insClass.CACHE_KEY.success) || new Array();
    const failCache = sessionStorage.getItem(insClass.CACHE_KEY.fail) || new Array();
    
    // ins.log.debug(methodName, 'successCache', successCache);
    // ins.log.debug(methodName, 'failCache', failCache);
    // ins.log.debug(methodName, 'encode', encode);

    if (successCache.includes(encode)) {
      return insClass.LOAD_STATUS.success;
    } else if (failCache.includes(encode)) {
      return insClass.LOAD_STATUS.fail;
    } else {
      return null;
    }
  }

  // 获取图片名称
  getImgName(path: string) {

  }

  // 显示图片
  showImg(imgObj: any): void {
    const methodName = this.setMethodName('showImg');
    const ins = this;
    const insClass = ConImg;
    const imgStatus = imgObj.getAttribute(insClass.IMG_ATTR.status);

    // ins.log.debug(methodName, imgObj, new Date().getTime());
    if (imgStatus == insClass.LOAD_STATUS.cache) return;
    // ins.log.debug(methodName, 'ins.maxCount', ins.maxCount);
    ins.showImgLoop(imgObj, ins.maxCount); 
  }

  // 显示图片【循环等待】
  showImgLoop(imgObj: any, count: number): void {
    const methodName = this.setMethodName('showImgLoop');
    const ins = this;
    const insClass = ConImg;
    const loadingTime = insClass.TIMEOUT;
    if (count == null) count = ins.maxCount;

    setTimeout(function(){
      const imgLoadStatus = imgObj.getAttribute(insClass.IMG_ATTR.status);
      // ins.log.debug(methodName, 'count', count, imgLoadStatus);

      if (imgLoadStatus == insClass.LOAD_STATUS.success) {
        const imgSrc = imgObj.getAttribute(insClass.IMG_ATTR.src);
        imgObj.setAttribute('src', imgSrc);
        imgObj.removeAttribute(insClass.IMG_ATTR.src);
        count = 0;
        return;
      } else if (imgLoadStatus == insClass.LOAD_STATUS.fail) {
        const imgSrcNone = ins.img.none;
        imgObj.setAttribute('src', imgSrcNone);
        count = 0;
        return;
      } else if (count > 0) {
        count--;
        ins.showImgLoop(imgObj, count);
      } else {
        const imgSrcNone = ins.img.none;
        imgObj.setAttribute('src', imgSrcNone);
      }
    }, loadingTime);
  }

  // 标记：加载成功
  loadingSuccess(imgObj: any): void {
    const ins = this;
    const insClass = ConImg;
    imgObj.setAttribute(insClass.IMG_ATTR.status, insClass.LOAD_STATUS.success);

    // 将 成功的src 加入缓存
    const imgSrc = imgObj.getAttribute(insClass.IMG_ATTR.src);
    ins.cacheSave(insClass.CACHE_KEY.success, imgSrc);
  }
  // 标记：加载失败
  loadingFail(imgObj: any): void {
    const ins = this;
    const insClass = ConImg;
    imgObj.setAttribute(insClass.IMG_ATTR.status, insClass.LOAD_STATUS.fail);

    // 将 失败的src 加入缓存
    const imgSrc = imgObj.getAttribute(insClass.IMG_ATTR.src);
    ins.cacheSave(insClass.CACHE_KEY.fail, imgSrc);
  }
  cacheSave(cacheKey: string, val: string): void {
    const encode = val;
    let cacheData = sessionStorage.getItem(cacheKey) || '';
    let cacheList = cacheData == '' ? [] : cacheData.split(',');
    if (!cacheList.includes(encode)) {
      cacheList.push(encode);
      cacheData = cacheList.join(',');
      sessionStorage.setItem(cacheKey, cacheData);
    }
  }
  // 标记：加载中
  loadingPending(imgObj: any): void {
    const insClass = ConImg;
    if (this.img.loading == '') return;
    
    imgObj.setAttribute('src', this.img.loading);
    imgObj.setAttribute(insClass.IMG_ATTR.status, insClass.LOAD_STATUS.pending);
  }
  // 标记：加载缓存
  loadingCache(imgObj: any): void {
    const insClass = ConImg;
    const imgSrc = imgObj.getAttribute(insClass.IMG_ATTR.src);
    imgObj.setAttribute('src', imgSrc);
    imgObj.setAttribute(insClass.IMG_ATTR.status, insClass.LOAD_STATUS.cache);
    imgObj.removeAttribute(insClass.IMG_ATTR.src);
  }
  // 标记：加载无图
  loadingNone(imgObj: any): void {
    const insClass = ConImg;
    const imgSrcNone = this.img.none;
    imgObj.setAttribute('src', imgSrcNone);
    imgObj.setAttribute(insClass.IMG_ATTR.status, insClass.LOAD_STATUS.cache);
  }

  // 限制图片大小
  resizeImg(): void {
    const ins = this;
    const methodName = this.setMethodName('resizeImg');

    const winWidth: number = window.innerWidth;
    ins.log.debug(methodName, 'winWidth:', winWidth);

    const imgList = document.querySelectorAll("img");
    imgList.forEach((img) => {
      const parentWidth: number = img.parentElement.offsetWidth;
      const imgWidth: number = img.offsetWidth;
      let setWith: any;
      ins.log.debug(methodName, 'img', img);
      if (imgWidth > winWidth || imgWidth > parentWidth) {
        if (winWidth > parentWidth) {
          setWith = parentWidth;
        } else {
          setWith = winWidth;
        }

        img.setAttribute('width', '');
        img.setAttribute('height', '');
        if (setWith > 0) img.style.width = setWith; 
        img.style.height = '';
      }
    });
  }
}
// class function ****** END